.pill-button {
  background-color: red;
  padding: 0.2rem 0;
  border-radius: 2rem;
  border: 1px solid red;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  margin: 0 0.5rem;
}

.pill-button:hover {
  background-color: transparent;
}

.pill-text {
  color: white;
  font-weight: bold;
}