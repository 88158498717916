.dn-container {
  position: absolute;
  top: 40px;
  display: none;
}

@media only screen and (min-width: 400px) {
  .dn-container {
    display: flex;
    justify-content: space-evenly;
    width: 90%;
  }
}

@media only screen and (min-width: 600px) {
  .dn-container {
    width: 70%;
  }
}