.sp-container {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: black;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.sp-overlay {
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.9));
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 400px) {
  .sp-container, .sp-overlay {
    min-height: 100vh;
  }
}