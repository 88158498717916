.container {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 95vh;
}

.logo {
  max-width: 40%;
}

.hero-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.03) 50%, rgba(0, 0, 0, 0.9) 100%);
  height: 95vh;
}

@media only screen and (min-width: 768px) {
  .container, .hero-overlay {
    height: 100vh;
  }
}
