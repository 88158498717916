.nav-container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 60px;
  z-index: 1;
}

@media only screen and (min-width: 400px) {
  .nav-container {
    display: none;
  }
}