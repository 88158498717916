@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap);
.footer {
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20vh;
  border-top: 2px solid red;
}

.footer-nav {
  display: flex;
  align-items: space-evenly;
  justify-content: space-evenly;
  width: 80%;
}

.footer-badge {
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0.3rem;
  border: 1px solid red;
}

.footer-badge:hover {
  background-color: transparent;
}

.footer-icon {
  color: white;
}

#nudge-left {
  padding-left: 0.25rem;
}

@media only screen and (min-width: 400px) {
  .footer-badge {
    padding: 0.5rem;
    font-size: 32px;
  }

  #nudge-left {
    padding-left: 0.5rem;
  }
}

@media only screen and (min-width: 800px) {
  .footer-badge {
    padding: 1rem;
    font-size: 40px;
  }

  #nudge-left {
    padding-left: 1rem;
  }
}
.pill-button {
  background-color: red;
  padding: 0.2rem 0;
  border-radius: 2rem;
  border: 1px solid red;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  margin: 0 0.5rem;
}

.pill-button:hover {
  background-color: transparent;
}

.pill-text {
  color: white;
  font-weight: bold;
}
.nav-container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 60px;
  z-index: 1;
}

@media only screen and (min-width: 400px) {
  .nav-container {
    display: none;
  }
}
.nav-link {
  color: red;
  font-size: 16px;
}

.nav-link:hover {
  color: white;
}
.dn-container {
  position: absolute;
  top: 40px;
  display: none;
}

@media only screen and (min-width: 400px) {
  .dn-container {
    display: flex;
    justify-content: space-evenly;
    width: 90%;
  }
}

@media only screen and (min-width: 600px) {
  .dn-container {
    width: 70%;
  }
}
.container {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 95vh;
}

.logo {
  max-width: 40%;
}

.hero-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.03) 50%, rgba(0, 0, 0, 0.9) 100%);
  height: 95vh;
}

@media only screen and (min-width: 768px) {
  .container, .hero-overlay {
    height: 100vh;
  }
}

.lr-container {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: black;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.lr-overlay {
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.9));
  display: flex;
  justify-content: center;
  align-items: center;
}

.lr-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lr-artwork {
  width: 120px;
  height: 120px;
  border: 2px solid red;
  margin-bottom: 0.5rem;
}

.lr-artwork:hover {
  box-shadow: 0 0 100px rgb(0, 0, 0);
}

.lr-caption {
  color: white;
}

.lr-caption:hover {
  color: red;
}

@media only screen and (min-width: 400px) {
  .lr-container, .lr-overlay {
    min-height: 100vh;
  }

  .lr-card {
    height: 80vh;
    padding: 2rem;
  }

  .lr-artwork {
    height: 100%;
    width: auto;
  }

  .lr-caption {
    font-size: 24px;
  }
}
.mobile-cta {
  background-color: red;
  width: 100%;
  display: none;
  justify-content: center;
}

.mobile-cta-text, .mobile-cta-link {
  color: white;
  margin: 0 3px;
}

.mobile-cta-link {
  text-decoration: underline;
}

@media only screen and (min-width: 400px) {
  .mobile-cta {
    display: none;
  }
}
.sp-container {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: black;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.sp-overlay {
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.9));
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 400px) {
  .sp-container, .sp-overlay {
    min-height: 100vh;
  }
}
.ml-container {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: black;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ml-overlay {
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.9));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ml-offer, .ml-description {
  color: white;
  text-align: center;
  padding: 0 2rem;
}

.ml-button {
  background-color: red;
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 2rem;
  margin: 1rem 0;
  border: 1px solid red;
}

.ml-button:hover {
  background-color: transparent;
}

@media only screen and (min-width: 400px) {
  .ml-container, .ml-overlay {
    min-height: 100vh;
  }

  .ml-offer, .ml-description {
    font-size: 24px;
  }

  .ml-button {
    font-size: 36px;
    padding: 2rem 4rem;
    border-radius: 4rem;
    margin: 4rem 0;
  }
}
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: Roboto;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: black;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

a {
  text-decoration: none;
}

