.footer {
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20vh;
  border-top: 2px solid red;
}

.footer-nav {
  display: flex;
  align-items: space-evenly;
  justify-content: space-evenly;
  width: 80%;
}

.footer-badge {
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0.3rem;
  border: 1px solid red;
}

.footer-badge:hover {
  background-color: transparent;
}

.footer-icon {
  color: white;
}

#nudge-left {
  padding-left: 0.25rem;
}

@media only screen and (min-width: 400px) {
  .footer-badge {
    padding: 0.5rem;
    font-size: 32px;
  }

  #nudge-left {
    padding-left: 0.5rem;
  }
}

@media only screen and (min-width: 800px) {
  .footer-badge {
    padding: 1rem;
    font-size: 40px;
  }

  #nudge-left {
    padding-left: 1rem;
  }
}